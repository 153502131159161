import { Injectable } from '@angular/core';
import {
  FeedbackResult,
  IPasswordStrengthMeterService,
} from 'angular-password-strength-meter';

@Injectable({
  providedIn: 'root'
})
export class CustomPsmServiceService extends IPasswordStrengthMeterService {
  scoreAsync(password: string): Promise<number> {
    throw new Error('Method not implemented.');
  }
  scoreWithFeedbackAsync(password: string): Promise<FeedbackResult> {
    throw new Error('Method not implemented.');
  }
  score(password: string): number {
    const passWithTrim = password.trim();

    if (passWithTrim.length >= 10 && /[A-Z]/.test(passWithTrim) && /\d/.test(passWithTrim)) {
      return 4;
    } else if (passWithTrim.length >= 8 && /[A-Z]/.test(passWithTrim) && /\d/.test(passWithTrim)) {
      return 3;
    } else if (passWithTrim.length >= 8 && /[A-Z]/.test(passWithTrim)) {
      return 2;
    } else if (passWithTrim.length >= 8) {
      return 1;
    } else {
      return 0;
    }
  }

  // example code for avoid the no-implement error
  scoreWithFeedback(
    password: string,
  ): {
    score: number;
    feedback: { warning: string; suggestions: string[] };
  } {
    return { score: 1, feedback: { warning: '', suggestions: [] } };
  }
}
